<template>
    <!--todo 机构的展示功能 -->
    <div class="SkuPurchaseEdit" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card>
            <el-page-header @back="goBack" />
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-form ref="data" label-width="80px" :model="data" @submit.native.prevent size="small">
                <el-form-item label="名称">
                    <el-input readonly v-model="form.sku.name" />
                </el-form-item>
                <el-form-item label="条码">
                    <el-input readonly :value="form.sku.bars.join(' / ')" />
                </el-form-item>
                <el-form-item label="规格">
                    <el-input readonly v-model="form.sku.specs" />
                </el-form-item>
                <el-form-item label="类目">
                    <el-input readonly v-model="form.sku.category" />
                </el-form-item>
                <el-form-item label="单位">
                    <el-input readonly v-model="form.sku.unit" />
                </el-form-item>
                <el-form-item label="品牌">
                    <el-input readonly v-model="form.sku.brand" />
                </el-form-item>
                <el-form-item label="机构">
                    <el-form-item label="总部" v-if="hasPrivilege('biz.skuPurchase.head.operate')">
                        <el-switch v-model="data.type1" active-text="是" inactive-text="否" />
                    </el-form-item>

                    <el-form-item label="配送中心" v-if="hasPrivilege('biz.skuPurchase.repo.operate')">
                        <el-switch v-model="data.type2" active-text="是" inactive-text="否" />
                    </el-form-item>

                    <el-form-item label="门店" v-if="hasPrivilege('biz.skuPurchase.store.operate')">
                        <el-switch v-model="data.type3" active-text="是" inactive-text="否" />
                    </el-form-item>
                </el-form-item>

                <el-form-item>
                    <el-button type="primary" @click="save" size="small">保存</el-button>
                </el-form-item>
            </el-form>
        </el-card>
    </div>
</template>
<script>
import UrlUtils from '../../../../js/UrlUtils';

export default {
    name: 'SkuPurchaseEdit',
    props: ['form'],
    data() {
        return {
            data: {},
        };
    },

    mounted() {
        this.data = this.form;
    },
    methods: {
        save() {
            const _this = this;
            const param = {
                type1: this.data.type1,
                type2: this.data.type2,
                type3: this.data.type3,
            };
            UrlUtils.PatchRemote(this, '/goods/sku/single/purchase/patch_c/' + _this.data.code, param, null, () => {
                this.$message.success('保存成功');
                this.goBackAndReload();
            });
        },
    },
};
</script>

<style scoped>
.el-input {
    width: auto;
}
</style>
